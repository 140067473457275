<script setup lang="ts">
import { Ref, inject, ref } from 'vue'

import AccountMenu from '@ankor-io/blocks/components/AccountMenu.vue'
import Banner from '@ankor-io/blocks/layouts/Banner.vue'
import OneColumnWithHeader from '@ankor-io/blocks/layouts/OneColumnWithHeader.vue'
import { SolidDocumentText, SolidRoute, SolidShip } from '@ankor-io/icons/solid'

const authenticationContext: any = inject('authenticationContext')!
const bannerOpen: Ref<boolean> = ref(false)
</script>
<template>
  <div>
    <Banner v-if="bannerOpen" @close="bannerOpen = false">
      <!-- p>Meet us at <strong>Cairns</strong>!</p -->
    </Banner>
    <OneColumnWithHeader
      :navigation="[
        {
          name: 'Presentations',
          href: '/trips/library/presentations',
          icon: SolidDocumentText,
          active: $route.path.startsWith('/trips/library/presentations'),
        },
        {
          name: 'Routes',
          href: '/trips/library/routes',
          icon: SolidRoute,
          active: $route.path.startsWith('/trips/library/routes'),
        },
        {
          name: 'Yachts',
          href: '/trips/library/yachts',
          icon: SolidShip,
          active: $route.path.startsWith('/trips/library/yachts'),
        },
      ]"
      :class-names="
        bannerOpen
          ? {
              content: 'h-[calc(100vh-2.75rem)]',
            }
          : undefined
      "
    >
      <template #header>
        <div class="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

            <!-- Profile dropdown -->
            <AccountMenu :name="authenticationContext.getUser()?.given_name" />
          </div>
        </div>
      </template>
      <template #default>
        <RouterView name="content" />
      </template>
    </OneColumnWithHeader>
  </div>
</template>
