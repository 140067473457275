<script lang="ts" setup>
import { inject, onMounted } from 'vue'
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router'

import { showErrorToast, showInfoToast } from '@ankor-io/blocks/components/toast'

import Spinner from '@/components/Spinner.vue'
import { AuthenticationContext } from '@/iam/types'

const props = defineProps<{
  uri?: string
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!
const route: RouteLocationNormalizedLoaded = useRoute()
const router: Router = useRouter()

onMounted(() => {
  if (route.name === 'trips-presentations-duplicate' && props.uri) {
    duplicate(props.uri)
  }
})

// Duplicates a presentation and navigates to the edit page
const duplicate = async (fromUri: string) => {
  const token = await authenticationContext.getToken()

  showInfoToast('Duplicating presentation...')

  const res: Response = await fetch('/api/new/from/proposal', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ uri: fromUri }),
  })

  if (res.ok) {
    const { uri }: { uri: string } = await res.json()
    router.replace(`/trips/editor/presentations/edit/${uri}`)
  } else {
    showErrorToast('Failed to duplicate presentation')
    console.debug('Failed to duplicate presentation', res)
  }
}
</script>
<template>
  <div v-if="$route.name === 'trips-presentations-duplicate'">
    <Spinner />
  </div>
  <div v-if="$route.name === 'trips-presentations-edit'">
    Edit {{ props.uri }}
    <Spinner />
  </div>
  <div v-if="$route.name === 'trips-presentations-new'">New presentation</div>
</template>
